import { FC } from 'react';
import cn from 'classnames';

import { menuModalClose } from '@rikstv/play-common/src/forces/ui/ui.slice';
import { useIsAuthenticated } from '@rikstv/play-common/src/hooks/useIsAuthenticated';

import { useRiksTvDispatch } from '../../../root.reducer';
import { MyPage, NewCustomer, PageNavigation } from '../header/Header';
import { BackMenuItem, LoginLogoutButton, SettingsMenuItem, TvGuideMenuItem } from '../header/menu';

import style from './MobileMenu.module.css';

export const MobileMenu: FC = () => {
  const isLoggedIn = useIsAuthenticated();
  const dispatch = useRiksTvDispatch();

  return (
    <div className={cn('rtv', style.mobileMenu)}>
      <header className={style.logoContainer}>
        <button
          onClick={() => dispatch(menuModalClose())}
          className={cn('with-keyboard-focus', style.modalCloseButton)}>
          <span />
        </button>
      </header>
      <nav>
        <ul className={style.navigationList}>
          <PageNavigation />
          <TvGuideMenuItem />
        </ul>
        <ul className={style.navigationList}>
          <li>
            <BackMenuItem />
          </li>
        </ul>
        <ul className={cn(style.navigationList)}>
          {isLoggedIn && <SettingsMenuItem />}
          {isLoggedIn ? <MyPage /> : <NewCustomer />}
          <li>
            <LoginLogoutButton />
          </li>
        </ul>
      </nav>
    </div>
  );
};
