import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { DeepLinkClipboardButton } from '@rikstv/play-common/src/components/deepLinkClipboardButton/DeepLinkClipboardButton';
import { FullWidthModal } from '@rikstv/play-common/src/components/modal/Modal';
import config from '@rikstv/play-common/src/config';
import { selectHeaderTheme, selectIsMenuModalOpen } from '@rikstv/play-common/src/forces/ui/selectors';
import type { FCC } from '@rikstv/play-common/src/utils/types/typeUtils';
import { CastMiniControls } from '@rikstv/play-player/src/chromecast/CastMiniControls/CastMiniControls';

import { Footer } from '../../components/footer/Footer';
import { Header } from '../../components/header/Header';
import { MobileMenu } from '../../components/mobilemenu/MobileMenu';

import style from './LayoutRiksTV.module.css';

export const LayoutRiksTV = ({
  darkLayout = false,
  children,
}: {
  darkLayout?: boolean;
  children?: React.ReactNode;
}) => {
  const isMenuModalOpen = useSelector(selectIsMenuModalOpen);
  const headerTheme = useSelector(selectHeaderTheme);

  return (
    <div className={style.layoutModern}>
      <Helmet defaultTitle="TV på PC & Mac - Se TV i nettleseren din her | RiksTV" titleTemplate="%s | RiksTV">
        {/* block search indexing for !prod  */}
        {config.isDevelopment && <meta name="robots" content="noindex" />}
        <meta property="og:url" content={window.location.href}></meta>
      </Helmet>
      <FullWidthModal open={isMenuModalOpen}>
        <MobileMenu />
      </FullWidthModal>
      <Header headerTheme={headerTheme} />
      <div id="main" className={classNames(style.main, { [style.darkLayout]: darkLayout })}>
        <DeepLinkClipboardButton />
        {children}
        <CastMiniControls />
      </div>
      <Footer />
    </div>
  );
};

export const DarkLayoutRiksTV: FCC = ({ children }) => {
  return <LayoutRiksTV darkLayout>{children}</LayoutRiksTV>;
};
