import { END, eventChannel } from 'redux-saga';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';

import { loginSuccess, tokenRenewed } from '@rikstv/play-common/src/forces/auth/auth.slice';
import { AccessToken } from '@rikstv/play-common/src/forces/auth/auth.types';
import { customerClaimsChanged } from '@rikstv/play-common/src/forces/webSocket/actions';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import logger from '@rikstv/play-common/src/utils/logger/logger';
import { AwaitedReturnType } from '@rikstv/play-common/src/utils/types/typeUtils';

import { rikstvAnalytics } from '../../../utils/analytics/rikstvAnalytics';

function* login() {
  // Create an event-channel to get a "dispatcher" we can use
  // from a callback that knows nothing about sagas
  let onTokenRenewed: (input: AccessToken | END) => void;
  const tokenRenewedChannel = eventChannel<AccessToken>(_emitter => {
    onTokenRenewed = _emitter;
    return () => void 0;
  });
  // init handles login callbacks and automatically refreshes token if needed
  const authStatus: AwaitedReturnType<typeof authService.init> = yield call([authService, authService.init], () => {
    const tokenObj = authService.getToken();
    onTokenRenewed(tokenObj);
    logger.info('new token expires', new Date(tokenObj.expiry));
  });
  if (authStatus === 'signedOut') {
    rikstvAnalytics.userLoggedOut();
  }
  if (authService.isAuthenticated()) {
    yield put(loginSuccess());
  }
  // listen for silent refresh
  while (true) {
    const tokenObj: AccessToken = yield take(tokenRenewedChannel);
    logger.info('got access token via channel emitter', tokenObj);
    yield put(tokenRenewed());
  }
}

function* reauthenticate() {
  logger.info('Reauthentication requested...');
  authService.renewTokens({ redirectUrl: window.location.href });
}

export function* authenticationSagas() {
  yield fork(login);
  yield takeEvery(customerClaimsChanged.type, reauthenticate);
}
