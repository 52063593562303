import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { loginSuccess, tokenRenewed } from '@rikstv/play-common/src/forces/auth/auth.slice';
import { commonSignUp, commonUpgrade } from '@rikstv/play-common/src/forces/loadUserInfo/actions';
import { userInfoLoaded } from '@rikstv/play-common/src/forces/loadUserInfo/loadUserInfo.slice';
import { callAuthenticated } from '@rikstv/play-common/src/forces/utils/callAuthenticated';
import { windowRedirect } from '@rikstv/play-common/src/router/router.utils';
import { errorTracker } from '@rikstv/play-common/src/utils/errorTracker/tracking';
import logger from '@rikstv/play-common/src/utils/logger/logger';
import { AwaitedReturnType } from '@rikstv/play-common/src/utils/types/typeUtils';

import { rtvConfig } from '../../../config';

import { getUserInfo } from './api';
import { authenticationSagas } from './sagasAuthentication';
import { loadedUser } from './userInfo.slice';

function* handleSignUp() {
  logger.info('Signup requested, redirecting to signup page');
  yield call(windowRedirect, rtvConfig.signUpRikspakkenUrl);
}

function* handleUpgrade() {
  logger.info('Upgrade requested, redirecting to upgrade page');
  try {
    // set referrer policy in meta tag before redirect so we get full path in referrer
    const meta = document.createElement('meta');
    meta.name = 'referrer';
    meta.content = 'no-referrer-when-downgrade';
    document.head.append(meta);
  } catch {
    /* om nom nom */
  }
  yield call(windowRedirect, rtvConfig.myPageUrl);
}

function* fetchUserInfo() {
  logger.info('Get user info');
  try {
    const userInfo: AwaitedReturnType<typeof getUserInfo> = yield call(callAuthenticated, getUserInfo);
    errorTracker.setUserId(userInfo.userId);
    yield put(loadedUser(userInfo));
    yield put(userInfoLoaded({ userInfoLoadSuccess: true }));
    logger.info('Received user info');
  } catch (err) {
    yield put(userInfoLoaded({ userInfoLoadSuccess: false, error: err }));
  }
}

export function* userSagas() {
  yield fork(authenticationSagas);
  yield all([
    takeLatest([loginSuccess.type, tokenRenewed.type], fetchUserInfo),
    takeLatest(commonSignUp.type, handleSignUp),
    takeLatest(commonUpgrade.type, handleUpgrade),
  ]);
}
